@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

body {
    @apply font-sans;
}

:root {
    --toastify-toast-width: 600px;
    --toastify-font-family: Roboto, sans-serif;
}

.Toastify__toast {
    border-radius: 0.5rem;
}
